.metrics {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.metric-card {
  background: #f3f3f3;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  flex: 1;
  margin: 0 10px;
}

.charts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.chart {
  width: 45%;
  margin: 20px 0;
}